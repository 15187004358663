import { useState } from 'react';
import "./Contact.css";
import { Row, Col, Card, FloatingLabel, Form, Button } from 'react-bootstrap';
import { GiPhone, GiPositionMarker } from 'react-icons/gi';
import { FaFax } from 'react-icons/fa6';
import { MdEmail } from 'react-icons/md';
import useApiService from '../../services/api/api';
import useInfoService, { useInfo } from '../../services/info/info';
import { useUser } from '../../services/user/user';


const Contact = () => {
    const user = useUser();
    const api = useApiService();
    const info = useInfo();

    const [disabled, setDisabled] = useState(false);

    const handleSendClick = () => {
        setDisabled(true);

        const name = document.getElementById("floatingName").value;
        const school = document.getElementById("floatingCompany").value;
        const tel = document.getElementById("floatingTel").value;
        const email = document.getElementById("floatingEmail").value;
        const message = document.getElementById("message").value;

        const formData = new FormData();

        formData.append("name", name);
        formData.append("school", school);
        formData.append("tel", tel);
        formData.append("email", email);
        formData.append("message", message);

        api.postDataFormData("contact", formData).then(() => {
            info.showInfo("Nachricht erfolgreich übermittelt.");

            document.getElementById("floatingName").value = "";
            document.getElementById("floatingCompany").value = "";
            document.getElementById("floatingTel").value = "";
            document.getElementById("floatingEmail").value = "";
            document.getElementById("message").value = "";
        }).finally(() => {
            setTimeout(() => {
                setDisabled(false);
            }, 5000);
        });
    }

    return (
        <div>
            <Row>   
                <Col>
                    <Card>
                        <Card.Body>
                            <Card.Title>Kontaktieren Sie uns!</Card.Title>
                            <Card.Text>
                                Sie haben Fragen zu uns oder unseren Produkten? Sie möchten weitere Informationen erhalten, einen Demotermin vereinbaren oder uns etwas mitteilen? Dann nutzen Sie dieses Formular, oder Sie rufen uns einfach an oder senden uns eine Email.
                            </Card.Text>
                            <Card.Text style={{display:'flex', gap:'30px', alignItems:'center'}}>
                                <GiPositionMarker style={{fontSize:'3rem', color:'#1798f4',}}/>
                                Benzstraße 17 | 48369 Saerbeck
                            </Card.Text>
                            <Card.Text style={{display:'flex', gap:'30px', alignItems:'center'}}>
                                <GiPhone style={{fontSize:'3rem', color:'#1798f4'}}/>
                                +49 (0) 2574 8885 0
                            </Card.Text>
                            <Card.Text style={{display:'flex', gap:'30px', alignItems:'center'}}>
                                <FaFax style={{fontSize:'3rem', color:'#1798f4'}}/>
                                +49 (0) 2574 8885 111
                            </Card.Text>
                            <Card.Text style={{display:'flex', gap:'30px', alignItems:'center'}}>
                                <MdEmail style={{fontSize:'3rem', color:'#1798f4'}}/>
                                info@schoolai.app®
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card>
                        <Card.Body>
                            <Card.Title>Kontaktformular</Card.Title>
                            <Card.Text>
                                <FloatingLabel controlId="floatingName" label="Name*" className="mb-3">
                                    <Form.Control type="text" placeholder="Name" />
                                </FloatingLabel>
                                <FloatingLabel controlId="floatingCompany" label="Schule" className="mb-3">
                                    <Form.Control type="text" placeholder="Schule" />
                                </FloatingLabel>
                                <FloatingLabel controlId="floatingTel" label="Telefon" className="mb-3">
                                <Form.Control type="text" placeholder="Telefon" />
                                </FloatingLabel>
                                <FloatingLabel controlId="floatingEmail" label="E-Mail*" className="mb-3">
                                    <Form.Control type="text" placeholder="E-Mail" />
                                </FloatingLabel>
                                <FloatingLabel controlId="message" label="Deine Nachricht" className="mb-3">
                                    <Form.Control as="textarea" style={{ height: '150px' }} />
                                </FloatingLabel>
                                <Button disabled={api.loading || disabled} variant="primary" style={{width:'100%'}} onClick={handleSendClick}>Absenden</Button>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default Contact;