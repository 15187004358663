import React from 'react';
import './Home.css';
import HeaderImg from '../../assets/imgs/Hintergrund_Header.jpg';
import ico from '../../assets/imgs/ico.png';
import homeImg from '../../assets/imgs/home.jpg';
import madeIn from '../../assets/imgs/SoftwareMadeFooter_KiX2.png';
import textImg from '../../assets/imgs/text.jpg';
import matheImg from '../../assets/imgs/mathe.jpg';
import quizImg from '../../assets/imgs/quiz.jpg';
import { AiOutlineSelect } from 'react-icons/ai';
import { MdLibraryAddCheck } from 'react-icons/md';
import { RiBook2Fill } from 'react-icons/ri';
import { GrDocument } from 'react-icons/gr';
import { Accordion, Button } from 'react-bootstrap';
import useRoutingService from '../../services/routing/routing';
import { useUser } from '../../services/user/user';

const Home = () => {
  const user = useUser();
  const routing = useRoutingService();

  return (
    <div className='home'>
      <img style={{display:'none'}} src={textImg} />
      <img style={{display:'none'}} src={matheImg} />
      <img style={{display:'none'}} src={quizImg} />
      <div
        className='headerbg'
        style={{
          backgroundImage: `url(${HeaderImg})`,
        }}
      ></div>
      <div>
        <div className='overlay'>
          <img src={ico}></img>
          <div>
            <span style={{color:'white', textShadow:'1px 1px 1px #777'}}>school</span>ai.app®
          </div>
        </div>
        <div style={{fontSize:'2rem', position:'absolute', width:'100%', top:'250px', textAlign:'center'}}>
          Für Schulen, Lehrer und Schüler
        </div>
      </div>
      <div className='white' style={{fontSize:'1.5rem', display:'flex', gap:'50px'}}>
        <div>
          <b>schoolai.app® – Intelligente Unterstützung für deine Schulaufgaben</b>
          <br/><br/>
          Mit schoolai.app® kannst du Schulaufgaben schnell und einfach mit Hilfe unseres Services generieren lassen. Egal, ob es um Mathe oder Deutsch geht – unser Service hilft dir dabei, Aufgaben individuell auf deine Bedürfnisse und den Unterrichtsstoff abzustimmen. Perfekt für Schüler, die effizient lernen möchten!
        </div>
        <div style={{
            backgroundImage: `url(${homeImg})`,
            width: '100%',
            backgroundPosition: 'center',
            backgroundSize: 'cover'
        }}>
        </div>
      </div>
      <div className='grey' style={{fontSize:'2rem', textAlign: 'center'}}>
        Die Bedienung ist kinderleicht!
      </div>
      <div className='white' style={{fontSize:'1.5rem', display:'flex', justifyContent:'space-between', gap:'50px'}}>
        <div style={{display:'flex', justifyContent:'start', alignItems:'center', flexDirection:'column', gap:'10px'}}>
          <div style={{fontSize:'4rem', color:'#1798f4'}}><RiBook2Fill/></div>
          <div style={{textAlign:'center'}}>Fach auswählen</div>
          <div style={{textAlign:'center', fontSize:'1rem'}}>Wähle das Fach aus, für das du eine Aufgabe erstellen möchtest.</div>
        </div>
        <div style={{display:'flex', justifyContent:'start', alignItems:'center', flexDirection:'column', gap:'10px'}}>
          <div style={{fontSize:'4rem', color:'#1798f4'}}><AiOutlineSelect /></div>
          <div style={{textAlign:'center'}}>Aufgabe auswählen</div>
          <div style={{textAlign:'center', fontSize:'1rem'}}>Entscheide dich für die Art der Aufgabe, die du generieren möchtest.</div>
        </div>
        <div style={{display:'flex', justifyContent:'start', alignItems:'center', flexDirection:'column', gap:'10px'}}>
          <div style={{fontSize:'4rem', color:'#1798f4'}}><MdLibraryAddCheck/></div>
          <div style={{textAlign:'center'}}>Fragen beantworten</div>
          <div style={{textAlign:'center', fontSize:'1rem'}}>Gib die nötigen Informationen ein, damit der Service eine passende Aufgabe erstellen kann.</div>
        </div>
        <div style={{display:'flex', justifyContent:'start', alignItems:'center', flexDirection:'column', gap:'10px'}}>
          <div style={{fontSize:'4rem', color:'#1798f4'}}><GrDocument/></div>
          <div style={{textAlign:'center'}}>PDF generieren</div>
          <div style={{textAlign:'center', fontSize:'1rem'}}>Erhalte deine personalisierte Aufgabe als PDF zum Download.</div>
        </div>
      </div>
      {!user.isLoggedIn() && (
        <div className='grey' style={{fontSize:'2rem', textAlign: 'center'}}>
          <div>Registriere dich jetzt und erstelle deine ersten Aufgabenblätter!</div>
          <div style={{marginTop:'20px'}}><Button variant="primary" size="lg" onClick={() => {user.openRegister()}}>Registrieren</Button></div>
        </div>
      )}
      {user.isLoggedIn() && (
        <div className='grey' style={{fontSize:'2rem', textAlign: 'center'}}>
          <div>Hey {user.getUser().firstname},</div>
          <div>schön, dass du wieder da bist! &#128515;</div>
        </div>
      )}
      <div className='white' style={{fontSize:'2rem', textAlign: 'center'}}>
        <div>Häufig gestellte Fragen (FAQs)</div>
        <div style={{marginTop:'30px', display:'flex',justifyContent:'center', gap:'20px', fontSize:'1rem'}}>
          <div style={{width:'50%'}}>
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Was ist schoolai.app®?</Accordion.Header>
                <Accordion.Body>
                  schoolai.app® ist eine Plattform, die es Lehrern und Schülern ermöglicht, Schulaufgaben mithilfe von Künstlicher Intelligenz zu erstellen. Egal ob Mathe, Sprachen oder Naturwissenschaften – die KI generiert Aufgaben, die perfekt auf den Unterrichtsstoff abgestimmt sind.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Wie funktioniert das Generieren von Aufgaben?</Accordion.Header>
                <Accordion.Body>
                  Du wählst einfach das Fach und die Art der Aufgabe aus, beantwortest einige Fragen zur gewünschten Aufgabe, und die KI erstellt daraus eine individuelle Schulaufgabe. Diese kannst du anschließend als PDF herunterladen.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>Ist die Nutzung von schoolai.app® kostenlos?</Accordion.Header>
                <Accordion.Body>
                  Nein, dieser Service ist mit Kosten verbunden die jeden Cent wert sind. Die Buchung eines Nutzungsabos beträgt 9,95,- Euro pro Monat bei einer Abomindestlaufzeit von drei Monaten.
                  <br/><br/>
                  Es gibt auch einen sog. Schulzugang, inklusive fünf Accounts für 499,- Euro pro Jahr.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>Wie kann ich für den Service bezahlen?</Accordion.Header>
                <Accordion.Body>
                  Die Bezahlung erfolgt über Paypal.
                  <br/><br/>
                  Für Schulen ist auch eine Bezahlung per Rechnung möglich.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>Wie sicher sind meine Daten?</Accordion.Header>
                <Accordion.Body>
                  Wir legen großen Wert auf den Schutz deiner Daten. Alle Informationen, die du eingibst, werden vertraulich behandelt und nicht an Dritte weitergegeben.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
          <div style={{width:'50%'}}>
            <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Welche Fächer werden unterstützt?</Accordion.Header>
                  <Accordion.Body>
                    Aktuell werden Mathematik und Deutsch unterstützt. Das Angebot wird aber stetig erweitert.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>Kann ich die generierten Aufgaben anpassen?</Accordion.Header>
                  <Accordion.Body>
                    Ja, du kannst vor dem Generieren der PDF die Parameter für die Aufgaben nach deinen Wünschen anpassen. So erhältst du genau die Aufgaben, die du brauchst.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>Kann ich Aufgaben speichern und später bearbeiten?</Accordion.Header>
                  <Accordion.Body>
                    Mit einem registrierten Konto kannst du Aufgaben speichern, bearbeiten und später erneut abrufen. Das erleichtert die Wiederverwendung von Aufgaben für zukünftige Lerneinheiten.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>Was mache ich, wenn ich ein Problem habe oder Unterstützung brauche?</Accordion.Header>
                  <Accordion.Body>
                    Solltest du auf ein Problem stoßen oder Hilfe benötigen, kannst du uns jederzeit über unser Kontaktformular oder per E-Mail erreichen. Unser Support-Team steht dir gerne zur Verfügung.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
          </div>
        </div>
      </div>
      <div className='grey' style={{fontSize:'1rem', display:'flex', justifyContent:'space-between'}}>
        <div style={{width:'25%'}}>
          <b>Über schoolai.app®</b>
          <br/><br/>
          schoolai.app® ist ein Service der Konzept-iX Software GmbH. Die Lösung basiert zum Teil auf einer künstlichen Intelligenz. Weitere Infos zu unseren Produkten findest du unter konzept-ix.com/software.
        </div>
        <div style={{
            backgroundImage: `url(${madeIn})`,
            width: '300px',
            height: '210px',
            backgroundPosition: 'center',
            backgroundSize: 'cover'
        }}>
        </div>
      </div>
    </div>
  );
};

export default Home;